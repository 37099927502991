import { useState } from "react";
// CSS
import { Logo } from "./components/Logo/Logo";
import { Video } from "./components/Video/Video";
import { WalkingPhrase } from "./components/WalkingPhrase/WalkingPhrase";
import { Description } from "./components/Description/Description";
import { Local } from "./components/Local/Local";

const linkList = [
  "/videos/WWD_JEJU_VIDEO_WEB_10_1.mp4",
  "/videos/WWD_CAELUM_VIDEO_WEB_01_1.mp4",
  "/videos/WWD_CURTS_VIDEO_WEB_02_1.mp4",
  "/videos/WWD_CAROMORETTI_VIDEO_WEB_03_1.mp4",
  "/videos/WWD_INA_VIDEO_WEB_04_1.mp4",
  "/videos/WWD_RENATA_FIGLIA_VIDEO_WEB_05_1.mp4",
  "/videos/WWD_DELISS_VIDEO_WEB_06_1.mp4",
  "/videos/WWD_EULIRICAS_VIDEO_WEB_07_1.mp4",
  "/videos/WWD_NOURISH_VIDEO_WEB_08_1.mp4",
  "/videos/WWD_ALURA_VIDEO_WEB_09_1.mp4",
  
];

const Home = () => {

  return (
    <main className="home">
      <div style={{ position: "relative" }}>
        <Description />
        <Logo />
        <Video
          videoQueue={linkList}
        />
        <WalkingPhrase />
        <Local />
      </div>
    </main>
  );
};

export default Home;
